//** Import Modules */
import {
  faLink,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Menu, message, Modal, Rate, Tooltip } from "antd";

//** Import Assets */
import noImage from "../../assets/images/Bizzll-DirectorySquare.png";

//** Import Components */
import { useState } from "react";
import BizzllWord from "../../components/common/BizzllWord";
import SupportEmailModal from "../../components/common/SupportEmailModal";
import { useSelector } from "react-redux";
import axiosGeneral from "../../utils/axiosGeneral";
import Highlighter from "../../components/common/Highlighter";

export default function ListingItem(props) {
  const { business } = props;
  const { keyword } = props;
  const user = useSelector((state) => state.user.user);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const listingData = {
    coverImg: business.meta_data.business_logo || noImage,
    name: business.name,
    userName: business.meta_data.business_handle,
    businessAddress: business.meta_data.business_address,
    businessPhone: business.meta_data.business_phone,
    category: business.meta_data.business_category,
    city: business.meta_data.business_city,
    state: business.meta_data.business_state,
    zip: business.meta_data.business_zip,
    package: business.meta_data.business_package_type,
    ratings: business.meta_data.business_ratings,
    totalRatings: business.meta_data.business_total_ratings,
    description: business.meta_data.business_description,
    shortDescription: business.meta_data.business_slogan,
    email: business.meta_data.business_email,
    addBorder: business.meta_data.business_front === "" ? "add-border" : "",
  };

  //* Function to format phone number
  const formatPhone = (phone) => {
    if (phone) {
      //normalize string and remove all unnecessary characters
      const newPhone = phone.replace(/[^\d]/g, "");

      if (newPhone.length === 10) {
        return newPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (newPhone.length === 11) {
        return newPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
      }
    }

    return phone;
  };

  const [modal1Visible, setModal1Visible] = useState(false);
  const [supportBizzllModal, setSupportBizzllModal] = useState(false);

  const handleClaimBusiness = async () => {
    setLoadingBtn(true);

    const APIURL = `/api/business/claimbusinessVerification`;

    await axiosGeneral()
      .post(APIURL, {
        emailtype: "business",
        businessId: business.id,
      })
      .then((response) => {
        if (response?.status == 200) {
          setModal1Visible(false);
          setLoadingBtn(false);

          Modal.success({
            title: "Request Sent",
            content: response.data.message,
          });
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        setLoadingBtn(false);
      });
  };

  //* Format address for Google Map
  const addressFormat = `${listingData.businessAddress}, ${listingData.city} ${listingData.state} ${listingData.zip}`;

  //* Open Google Map Directions when clicked
  const openDirections = async () => {
    const response = await axiosGeneral().post(
      `${process.env.REACT_APP_BE_API_URL}/api/business/visitor`,
      {
        typeId: business.id,
        component: "address_click",
        userId: user?.id,
        type: "business",
      }
    );
    window.open(`https://www.google.com/maps/dir//${addressFormat}`);
  };

  const handleBusinessPhoneClick = async () => {
    const response = await axiosGeneral().post(
      `${process.env.REACT_APP_BE_API_URL}/api/business/visitor`,
      {
        typeId: business.id,
        component: "phone_click",
        userId: user?.id,
        type: "business",
      }
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key={1} onClick={() => setSupportBizzllModal(true)}>
        Claim via Bizzll Support
      </Menu.Item>
      {listingData.email ? (
        <Menu.Item key={2} onClick={() => handleClaimBusiness()}>
          Claim via Business Email on file
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <>
      <SupportEmailModal
        title="Claim Business"
        supportBizzllModal={supportBizzllModal}
        setSupportBizzllModal={setSupportBizzllModal}
        setbackModal={setModal1Visible}
        loadingBtn={loadingBtn}
        setLoadingBtn={setLoadingBtn}
        businessId={business.id}
        emailtype="supportEmail"
      />
      <div className="listing-item">
        <div className={`cover-img ${listingData.addBorder}`}>
          <div
            className="image"
            style={{ backgroundImage: `url(${listingData.coverImg})` }}
          ></div>
        </div>

        <div className="business-info">
          <div className="top-row">
            <div className="business-name">
              <h3>
                <Highlighter
                  searchKeyword={keyword}
                  highlightText={listingData.name}
                />
              </h3>
            </div>

            <div className="top-row-meta">
              {!business.owner_id && (
                <Dropdown overlay={menu} placement="bottomRight">
                  <Button
                    type="primary"
                    loading={loadingBtn}
                    className="claim-business-btn"
                  >
                    Claim
                  </Button>
                </Dropdown>
              )}

              {business.owner_id && listingData.package === "paid" && (
                <>
                  <div className="reviews">
                    <div className="stars">
                      <Rate
                        defaultValue={listingData.ratings || 0}
                        disabled={true}
                        allowHalf
                      />
                    </div>
                  </div>

                  <div className="bizzll-badge">
                    <BizzllWord useImage={true} />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="business-description">
            <p>
              <Highlighter
                searchKeyword={keyword}
                highlightText={listingData.shortDescription}
              />
            </p>

            <div className="business-meta">
              <div className="phone">
                {formatPhone(listingData.businessPhone)}
              </div>
              <div className="address">
                <div
                  style={{
                    display: "block",
                  }}
                >
                  {listingData.businessAddress}
                </div>
                <span style={{ marginTop: "4px", display: "block" }}>
                  {listingData.city}, {listingData.state} {listingData.zip}
                </span>
              </div>
            </div>
          </div>

          <div className="bottom-row">
            <div className="business-category">
              {listingData.category && (
                <span className="category-box">
                  <Highlighter
                    searchKeyword={keyword}
                    highlightText={listingData.category}
                  />
                </span>
              )}
            </div>

            <div className="listing-buttons">
              {listingData.userName && (
                <Tooltip title="Business Page" placement="top" color="#fb4400">
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon icon={faLink} />}
                    href={`/business/${listingData.userName}`}
                    size="large"
                  />
                </Tooltip>
              )}

              <Tooltip title="Get Directions" placement="top" color="#fb4400">
                <Button
                  type="primary"
                  icon={<FontAwesomeIcon icon={faLocationDot} />}
                  onClick={openDirections}
                  size="large"
                />
              </Tooltip>

              {listingData.businessPhone && (
                <Tooltip title="Call" placement="top" color="#fb4400">
                  <Button
                    type="primary"
                    icon={<FontAwesomeIcon icon={faPhone} />}
                    onClick={handleBusinessPhoneClick}
                    size="large"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
