//** Temp Assets */
import { Button, Checkbox, Input, Select, Divider } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Advertisements from "../../components/ads/Advertisements";
import axiosGeneral from "../../utils/axiosGeneral";
const { Option } = Select;

export default function DirectorySidebar(props) {
  const { businesses } = props;
  const search = useLocation().search;
  let navigate = useNavigate();

  const [businessName, setBusinessName] = useState(
    new URLSearchParams(search).get("keyword")
  );
  const [selectedCategory, setSelectedCategory] = useState(
    new URLSearchParams(search).get("category")
  );
  const [city, setCity] = useState(new URLSearchParams(search).get("location"));
  const [status, setStatus] = useState(
    new URLSearchParams(search).get("status")
  );
  const [ratings, setRatings] = useState(
    new URLSearchParams(search).get("ratings")
  );
  const [filteredTag, setFilteredTag] = useState(
    new URLSearchParams(search).get("tags")?.split(",")
  );

  const [businessLink, setbusinessLink] = useState("");
  const [headerSearchValue, setHeaderSearchValue] = useState({});
  const [filterBtnVisible, setFilterBtnVisible] = useState(false);
  const [multipleFilterBtnVisible, setMultipleFilterBtnVisible] =
    useState(false);
  let [multipleTags, setMultipleTags] = useState([]);
  let locationQuery = "";
  let nameQuery = "";
  let CategoryQuery = "";
  let ratingsQuery = "";
  let filteredTagQuery = "";
  let statusQuery = "";
  if (city) {
    locationQuery = `&location=${city || ""}`;
  }
  if (businessName) {
    nameQuery = `&keyword=${businessName || ""}`;
  }
  if (selectedCategory) {
    CategoryQuery = `&category=${selectedCategory || ""}`;
  }
  if (ratings) {
    ratingsQuery = `&ratings=${ratings || ""}`;
  }

  if (filteredTag && filteredTag.length > 0) {
    filteredTagQuery = `&tags=${filteredTag || ""}`;
  }
  if (status) {
    statusQuery = `&status=${status || ""}`;
  }

  useEffect(() => {
    if (!search) {
      setBusinessName(null);
      setSelectedCategory(null);
      setCity(null);
      setRatings(null);
      setFilteredTag(null);
    } else {
      setBusinessName(new URLSearchParams(search).get("keyword"));
      setSelectedCategory(new URLSearchParams(search).get("category"));
      setCity(new URLSearchParams(search).get("location"));
      setFilteredTag(new URLSearchParams(search).get("tags")?.split(","));
    }
  }, [search]);

  useEffect(() => {
    if (filteredTag && filteredTag.includes(",")) {
      let selectedTags = filteredTag.split(",");
      setMultipleTags(selectedTags);
    } else {
      setMultipleTags(filteredTag);
    }
  }, [filteredTag]);

  useEffect(() => {
    /**
     * If There are no Tags Then disable Filter Btn of Tags
     * if filterBtnVisible state to true then Btn will be disabled
     */
    if (props.tags && props.tags.length > 0) {
      setFilterBtnVisible(false);
    } else {
      setFilterBtnVisible(true);
    }
  }, [props.searchData, props.tags]);

  // const categories = [
  //   {
  //     value: "Accounting",
  //     key: "accounting",
  //   },
  //   {
  //     value: "Design",
  //     key: "design",
  //   },
  //   {
  //     value: "Restaurants",
  //     key: "restaurants",
  //   },
  // ];

  //* Get Categories
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    const APIURL = `/api/nomenclators/list/business_subcategory`;

    axiosGeneral()
      .get(APIURL)
      .then((res) => {
        const categoriesList = res.data.map((category) => {
          const lowercaseFirstLetterName =
            category.name.charAt(0).toLowerCase() + category.name.slice(1);

          return {
            value: category.name,
            key: lowercaseFirstLetterName,
          };
        });

        setCategories(categoriesList);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleTagsChange = () => {
    if (
      !city &&
      !businessName &&
      !selectedCategory &&
      !ratings &&
      !filteredTag &&
      !status
    ) {
      setMultipleFilterBtnVisible(true);
    } else {
      navigate(
        `/directory?searchDataPage=${0}${locationQuery}${nameQuery}${CategoryQuery}${ratingsQuery}${filteredTagQuery}${statusQuery}`
      );
    }
  };

  const handleSearchSubmit = () => {
    if (!selectedCategory || (filteredTag && filteredTag.length)) {
      navigate(
        `/directory?searchDataPage=${0}${locationQuery}${nameQuery}${CategoryQuery}${ratingsQuery}${statusQuery}`
      );
    } else if (
      city ||
      businessName ||
      selectedCategory ||
      ratings ||
      (filteredTag && filteredTag.length) ||
      status
    ) {
      navigate(
        `/directory?searchDataPage=${0}${locationQuery}${nameQuery}${CategoryQuery}${ratingsQuery}${filteredTagQuery}${statusQuery}`
      );
    } else {
      props.setSearchData(search);
      navigate(`/directory`);
    }
  };

  return (
    <>
      <div className="ad-space">
        <Advertisements addLocation="directorySidebar" type="square" />
        <Divider />
        <Advertisements addLocation="directorySidebar" type="square" />
      </div>

      <div className="widget-box filter">
        <h3>Filter</h3>

        <Select
          style={{
            width: "100%",
          }}
          onChange={(value) => {
            setSelectedCategory(value);
          }}
          size="large"
          allowClear={true}
          placeholder="Select Category"
          value={
            categories
              .map((category) => category.value)
              .includes(selectedCategory)
              ? selectedCategory
              : null
          }
        >
          {categories.map((category) => {
            return (
              <Option
                selected={headerSearchValue.category === category.value}
                value={category.value}
              >
                {category.value}
              </Option>
            );
          })}
        </Select>

        <Select
          style={{
            width: "100%",
            marginTop: 10,
          }}
          onChange={(value) => {
            setCity(value);
          }}
          size="large"
          allowClear={true}
          placeholder="Select City"
          value={
            city &&
            props.cities &&
            props.cities.length &&
            props.cities.map((city) => city.name).includes(city)
              ? city
              : null
          }
        >
          {props.cities
            ? props.cities.map((data) => {
                return <Option value={data.name}>{data.name}</Option>;
              })
            : null}
        </Select>

        <Select
          style={{
            width: "100%",
            marginTop: 10,
            marginBottom: 15,
          }}
          onChange={(value) => setRatings(value)}
          size="large"
          allowClear={true}
          value={ratings}
          placeholder="Select Ratings"
        >
          <Option value="4-5">4 and higher</Option>
          <Option value="3-4">3 - 4 stars</Option>
          <Option value="2-3">2 - 3 stars</Option>
          <Option value="1-2">1 - 2 stars</Option>
          <Option value="0-1">0 - 1 stars</Option>
        </Select>

        <Button
          onClick={handleSearchSubmit}
          type="primary"
          disabled={multipleFilterBtnVisible}
        >
          Filter
        </Button>
      </div>

      {new URLSearchParams(search).get("category") && (
        <div className="widget-box search-form">
          <h3>Filter by Tags</h3>
          <div className="tags-list">
            {props.tags && props.tags.length
              ? props.tags.map((data) => {
                  return (
                    <div>
                      <Checkbox
                        checked={
                          filteredTag &&
                          filteredTag.includes(data.id.toString())
                            ? true
                            : false
                        }
                        style={{
                          margin: "10px",
                        }}
                        onChange={() => {
                          if (
                            filteredTag &&
                            filteredTag.includes(data.id.toString())
                          ) {
                            setFilteredTag(
                              filteredTag.filter(
                                (e) => e !== data.id.toString()
                              )
                            );
                          } else {
                            if (filteredTag && filteredTag.length > 0) {
                              setFilteredTag([
                                ...filteredTag,
                                data.id.toString(),
                              ]);
                            } else {
                              setFilteredTag([data.id.toString()]);
                            }
                          }
                        }}
                        defaultChecked={
                          multipleTags &&
                          multipleTags.includes(data.id.toString())
                            ? true
                            : false
                        }
                      >
                        {" "}
                        {data.term_name}
                      </Checkbox>
                    </div>
                  );
                })
              : null}
          </div>
          <Button
            disabled={filterBtnVisible}
            type="primary"
            onClick={handleTagsChange}
          >
            Filter
          </Button>
        </div>
      )}
    </>
  );
}
