import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import RegisterAttendee from "./RegisterAttendee";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import ShareBar from "./ShareBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";

export default function Sidebar({ eventData, user }) {
  const [isAttending, setIsAttending] = useState(false);
  const [isMeetingTime, setIsMeetingTime] = useState(false);
  const metaData = eventData.meta_data;

  useEffect(() => {
    if (user.id) {
      const APIURL = `/api/event-ticket/attending`;

      axiosWithAuth()
        .post(APIURL, {
          idEvent: eventData.id,
        })
        .then((res) => {
          setIsAttending(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [metaData]);

  useEffect(() => {
    if (metaData) {
      const startTime = metaData.event_starttime;
      const endTime = metaData.event_endtime;
      const eventDate = metaData.event_date?.split(" ")[0];

      if (startTime && endTime && eventDate) {
        const eventStartDateTime = moment(
          `${eventDate} ${startTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const eventEndDateTime = moment(
          `${eventDate} ${endTime}`,
          "YYYY-MM-DD HH:mm:ss"
        );
        const now = moment();

        if (eventStartDateTime.isValid() && eventEndDateTime.isValid()) {
          const minutesToStart = moment
            .duration(eventStartDateTime.diff(now))
            .asMinutes();
          const minutesToEnd = moment
            .duration(eventEndDateTime.diff(now))
            .asMinutes();
          setIsMeetingTime(true);

          if (minutesToStart <= 30 && minutesToEnd >= 0) {
            setIsMeetingTime(true); // Dentro del rango permitido
          } else {
            setIsMeetingTime(false); // Fuera del rango permitido
          }
        }
      }
    }
  }, [metaData]);

  const joinEvent = async () => {
    const APIURL =
      user.id === eventData.owner_id
        ? `/api/jitsi-meet/accessAuth`
        : `/api/jitsi-meet/accessAuthClient`;
    const data = {
      id: eventData.id,
      owner_id: eventData.owner_id,
    };

    try {
      await axiosWithAuth()
        .post(APIURL, {
          roomName: metaData.meet_jitsi,
          user: {
            id: user.id,
            name: user.user_login,
            email: user.email,
          },
          eventData: data,
          typeEvent: "event",
        })
        .then((res) => {
          const domain = process.env.REACT_APP_JITSI_DOMAIN;

          const jitsiServerURL = `https://${domain}/${metaData.meet_jitsi}?jwt=${res.data}`;
          window.open(jitsiServerURL, "_blank");
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="widget-box button-widget">
        {isAttending || user.id === eventData.owner_id ? (
          <Button
            type="primary"
            onClick={joinEvent}
            disabled={!isMeetingTime}
            icon={<FontAwesomeIcon icon={faSignInAlt} />}
          >
            Join Event
          </Button>
        ) : (
          user.id !== eventData.owner_id && (
            <RegisterAttendee eventData={eventData} />
          )
        )}
      </div>

      <div className="widget-box">
        <h3>Event Time</h3>

        <div className="meta-data">
          <div className="label">Event Date:</div>
          <div className="data">
            {moment(metaData.event_date).format("MMM. Do, YYYY")}
          </div>
        </div>

        <div className="meta-data">
          <div className="label">Start:</div>
          <div className="data">
            {moment(metaData.event_starttime, "HH:mm").format("hh:mm A")}
          </div>
        </div>

        <div className="meta-data">
          <div className="label">End:</div>
          <div className="data">
            {moment(metaData.event_endtime, "HH:mm").format("hh:mm A")}
          </div>
        </div>

        <AddToCalendarButton
          name={eventData.name}
          options={["Apple", "Google", "Microsoft365", "Outlook.com", "iCal"]}
          location={
            metaData.event_mode === "online" ? "Online" : metaData.event_venue
          }
          startDate={moment(metaData.event_date).format("YYYY-MM-DD")}
          endDate={moment(metaData.event_date).format("YYYY-MM-DD")}
          startTime={metaData.event_starttime}
          endTime={metaData.event_endtime}
          timeZone="America/New_York"
          hideCheckmark={true}
          trigger={"click"}
        ></AddToCalendarButton>
      </div>

      <div className="widget-box">
        <h3>Event Details</h3>

        <div className="meta-data">
          <div className="label">Capacity:</div>
          <div className="data">{metaData.event_capacity} Attendees</div>
        </div>

        <div className="meta-data">
          <div className="label">Category:</div>
          <div className="data">{metaData.event_category}</div>
        </div>

        <div className="meta-data">
          <div className="label">Location:</div>
          <div className="data">
            {metaData.event_mode === "online" ? "Online" : metaData.event_venue}
          </div>
        </div>
      </div>

      <div className="widget-box">
        <ShareBar eventData={eventData} />
      </div>

      <div className="widget-box button-widget">
        {isAttending ? (
          <Button
            type="primary"
            onClick={joinEvent}
            icon={<FontAwesomeIcon icon={faSignInAlt} />}
          >
            Join Event
          </Button>
        ) : (
          user.id !== eventData.owner_id && (
            <RegisterAttendee eventData={eventData} />
          )
        )}
      </div>
    </>
  );
}
