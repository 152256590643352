// import React, { useEffect, useState } from "react";

// //* Placeholder Assets
// import square from "../../assets/images/ad-space-square.jpg";
// import skyscraper from "../../assets/images/ad-space-skyscraper.jpg";
// import axiosGeneral from "../../utils/axiosGeneral";
// import { Carousel } from "antd";

// export default function Advertisements(props) {
//   const { type } = props;

//   const [ad, setAd] = useState({});

//   useEffect(() => {
//     getAds();
//   }, []);

//   const renderAd = (ad) => (
//     <a href={`/business/${ad.businessHandle}`} key={ad.id}>
//       <img
//         src={
//           ad.meta_data.find((meta) => meta.meta_key === "ad_image").meta_value
//         }
//         alt={`${type} Ad`}
//         style={{ width: "100%" }}
//       />
//     </a>
//   );

//   const getAds = async () => {
//     try {
//       const response = await axiosGeneral().get(
//         `/api/advertisement/getAddsForPublic/${
//           type === "square" ? "free" : "pay"
//         }`
//       );
//       console.log("response", response.data[0]);
//       setAd(response.data[0]);
//       // return response.data;
//     } catch (error) {
//       console.error("Error getting user ads:", error);
//     }
//   };

//   const contentStyle = {
//     height: "160px",
//     color: "#fff",
//     lineHeight: "160px",
//     textAlign: "center",
//     background: "#364d79",
//   };

//   return (
//     <div className="advertisement">
//       {type === "square" &&
//         (ad.id ? (
//           <a href={`/business/${ad.businessHandle}`}>
//             <img
//               src={
//                 ad.meta_data.find((meta) => meta.meta_key === "ad_image")
//                   .meta_value
//               }
//               alt="Square Ad"
//             />
//           </a>
//         ) : (
//           <img src={square} alt="Square Ad Placeholder" />
//         ))}

//       {type === "skyscraper" &&
//         (ad.id ? (
//           <a href={`/business/${ad.businessHandle}`}>
//             <img src={ad.meta_data.ad_image} alt="Skyscraper Ad" />
//           </a>
//         ) : (
//           <img src={skyscraper} alt="Skyscraper Ad Placeholder" />
//         ))}

//       {type === "carousel" && (
//         <Carousel autoplay>
//           {/* {ad.length > 0 ? (
//             ad.map(renderAd)
//           ) : (
//             <img src={square} alt="Ad Placeholder" />
//           )} */}
//           <div>
//             <h3 style={contentStyle}>1</h3>
//           </div>
//           <div>
//             <h3 style={contentStyle}>2</h3>
//           </div>
//           <div>
//             <h3 style={contentStyle}>3</h3>
//           </div>
//           <div>
//             <h3 style={contentStyle}>4</h3>
//           </div>
//         </Carousel>
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";

//* Placeholder Assets
import square from "../../assets/images/ad-space-square.jpg";
import carousel from "../../assets/images/ad-space-carousel.jpg";
import skyscraper from "../../assets/images/ad-space-skyscraper.jpg";
import axiosGeneral from "../../utils/axiosGeneral";
import { Carousel } from "antd";

export default function Advertisements(props) {
  const { type } = props;

  const [ad, setAd] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    getAds();
  }, []);

  useEffect(() => {
    if (type === "square" && ad.length > 0) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * ad.length);
        setCurrentAdIndex(randomIndex);
      }, 10000); // Cambiar cada 10 segundos
      return () => clearInterval(interval); // Limpiar el intervalo al desmontar
    }
  }, [ad, type]);

  const renderAd = (ad) => {
    const adUrl = ad.meta_data.find(
      (meta) => meta.meta_key === "ad_url"
    )?.meta_value;
    const adImage =
      ad.meta_data.find((meta) => meta.meta_key === "ad_image")?.meta_value ||
      "default-image-url.jpg";

    return adUrl ? (
      <a href={adUrl} key={ad.id}>
        <img src={adImage} alt={`${type} Ad`} style={{ width: "100%" }} />
      </a>
    ) : (
      <img
        src={adImage}
        alt={`${type} Ad`}
        style={{ width: "100%" }}
        key={ad.id}
      />
    );
  };

  const getAds = async () => {
    try {
      const response = await axiosGeneral().get(
        `/api/advertisement/getAddsForPublic/${
          type === "square" ? "free" : "pay"
        }`
      );
      setAd(response.data || null); // Set null if no ad is found
    } catch (error) {
      console.error("Error getting user ads:", error);
    }
  };

  if (!ad) {
    return null; // Return nothing if ad is empty or undefined
  }

  return (
    <div className="advertisement">
      {type === "square" && (
        <>
          {Array.isArray(ad) && ad.length > 0 ? (
            <a
              href={
                ad[currentAdIndex]?.meta_data.find(
                  (meta) => meta.meta_key === "ad_url"
                ).meta_value
              }
            >
              {console.log(
                "aaaaaaaaaaaaaaaaaaaa",
                ad[currentAdIndex].meta_data.find(
                  (meta) => meta.meta_key === "ad_url"
                ).meta_value
              )}
              <img
                src={
                  ad[currentAdIndex]?.meta_data.find(
                    (meta) => meta.meta_key === "ad_image"
                  )?.meta_value || square
                }
                alt="Square Ad"
              />
            </a>
          ) : (
            <img src={square} alt="Ad Placeholder" />
          )}
        </>
      )}

      {type === "skyscraper" && (
        <a href={`/business/${ad.businessHandle}`}>
          <img
            src={
              ad[0].meta_data.find((meta) => meta.meta_key === "ad_image")
                .meta_value
            }
            alt="Skyscraper Ad"
          />
        </a>
      )}

      {type === "carousel" && (
        <Carousel autoplay>
          {Array.isArray(ad) && ad.length > 0 ? (
            ad.map(renderAd)
          ) : (
            <img src={carousel} alt="Ad Placeholder" />
          )}
        </Carousel>
      )}
    </div>
  );
}
