//** Import Modules */
import { Button, Form, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import axiosWithAuth from "../../utils/axiosWithAuth";

//** Import Components */
import ChatBoxMessage from "./ChatBoxMessage";
import { faPaperPlane, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { io } from "socket.io-client";
import { useSelector } from "react-redux";

export default function ChatBox(props) {
  const { chatbox, closeChatbox } = props;

  const { sender, receiver, is_business } = chatbox;

  const user = useSelector((state) => state.user.user);

  const [messages, setMessages] = useState([]);
  const [threadID, setThreadID] = useState(0);
  const [chatToken, setChatToken] = useState();
  const [userToken, setUserToken] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState();

  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const messageForm = useRef(null);

  useEffect(() => {
    createChat();
  }, []);

  useEffect(() => {
    if (threadID !== 0 && is_business !== null && user.id) {
      updateSeenChats();
    }
  }, [threadID, is_business, user.id]);

  useEffect(() => {
    user.meta_data.forEach((element) => {
      if (element.meta_key === "login_session") {
        setUserToken(element.meta_value);
      }
    });
    getUnseenMessagesAmount();
  }, [user]);

  useEffect(() => {
    if (threadID && chatToken) {
      connectToChat(chatToken);
    }
  }, [threadID, chatToken]);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (threadID !== 0) getMessages();
  }, [threadID]);

  const createChat = async () => {
    try {
      const data = {
        recipientID: receiver.id,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/create/thread`,
        data
      );
      setThreadID(response.data.idThread);
      getChatToken(response.data.idThread);
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  const getChatToken = async (ThreadID) => {
    try {
      const response = await axiosWithAuth().get(
        `/api/messages/keyThreads/${ThreadID}`
      );
      setChatToken(response.data.key);
    } catch (error) {
      console.error("Error getting chat token:", error);
    }
  };

  const connectToChat = async (token) => {
    console.log("Connecting to chat...");

    if (socket) {
      socket.disconnect();
    }

    try {
      let socketio = io(process.env.REACT_APP_BE_API_URL, {
        query: {
          token: token,
        },
      });

      socketio.on("connect", () => {
        console.log("Connected to chat");
        setSocket(socketio);
        setIsConnected(true);
      });

      socketio.on("message", (data) => {
        console.log("Message received", data);
        setMessages((prevMessages) => [...prevMessages, data]);
      });

      socketio.on("disconnect", () => {
        console.log("Disconnected from chat");
        setIsConnected(false);
      });
    } catch (error) {
      console.error("Error connecting to chat:", error);
      setIsConnected(false);
    }
  };

  const getMessages = async () => {
    try {
      const data = {
        idParticipant: receiver.id,
        isBusiness: true,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/message/${threadID}`,
        data
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Error getting chat token:", error);
    }
  };

  const onFinish = async (values) => {
    if (isConnected && socket) {
      const data = {
        chat_id: threadID,
        sender_id: userToken,
        message: values.message,
        sender_type: "user",
        receive_id: receiver.id,
      };

      socket.emit("message", data);
      form.resetFields();
      inputRef.current.focus();
    } else {
      console.error("Socket is not connected");
    }
  };

  const updateSeenChats = async () => {
    try {
      const data = {
        idThread: threadID,
        isBusiness: is_business,
        idRegister: user.id,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/unseen/update`,
        data
      );
      console.log("response", response);
    } catch (error) {
      console.error("Error updating chat:", error);
    }
  };

  const getUnseenMessagesAmount = async () => {
    try {
      const data = {
        type: is_business ? "business" : "user",
      };
      const response = await axiosWithAuth().post(
        `/api/messages/getunseen/${user.id}`,
        data
      );
      console.log("getUnseenMessagesAmount response", response);
    } catch (error) {
      console.error("Error getting unseen messages chat:", error);
    }
  };

  return (
    <div>
      <div className="new-chatbox">
        <div className="chatbox-header">
          <div className="recipient-name">
            {isConnected ? <p>Connected</p> : <p>Connecting to chat...</p>}
          </div>

          <div
            className="close"
            onClick={() => {
              if (closeChatbox) {
                closeChatbox();
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
        </div>

        <div className="chatbox-body">
          <div className="messages-box">
            {messages.length > 0
              ? messages.map((element, index) => {
                  return (
                    <ChatBoxMessage
                      key={index + "_" + userToken + "_" + element.sender_id}
                      message={element.message}
                      user={user}
                      sender_id={element.sender_id}
                    />
                  );
                })
              : "No Messages Found"}

            <div ref={messagesEndRef} />
          </div>
          <Form
            onFinish={onFinish}
            form={form}
            autoComplete="off"
            ref={messageForm}
          >
            <Form.Item
              name="message"
              rules={[{ required: true, message: "Please input a message!" }]}
            >
              <Input ref={inputRef} placeholder="Type your message..." />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button htmlType="submit">
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
