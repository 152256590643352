import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu } from "antd";
import moment from "moment";
import { useState } from "react";
import Comment from "./Comment";
import CommentAdForm from "./CommentAdForm";
import CommentModal from "./CommentModal";
import CreateAdModal from "./CreatePostModal";
import DeleteModal from "./DeleteModal";
import LikeModal from "./LikeModal";
import ShareModal from "./ShareModal";

const SingleAdvertisement = (props) => {
  const {
    group,
    advertisement,
    user,
    deleteAdvertisement,
    updateAdvertisement,
    isSubmitting,
    likeAdvertisement,
    unlikeAdvertisement,
    commentAdvertisement,
    liked,
    commentData,
    shareAdvertisement,
    deleteComment,
    entity,
    userBlocked,
  } = props;
  const [showLikeModal, setShowLikeModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAdAction = async (e) => {
    if (e.key === "delete-ad") {
      setShowDeleteModal(true);
    } else if (e.key === "update-ad") {
      setShowUpdateModal(true);
    }
  };

  const handleAdShare = async (e) => {
    if (e.key === "facebook") {
      // setShowDeleteModal(true);
    } else if (e.key === "twitter") {
      // setShowUpdateModal(true);
    }
  };

  const menu = (
    <Menu onClick={handleAdAction}>
      <Menu.Item key="update-ad">Edit</Menu.Item>
      <Menu.Item key="delete-ad" className="menu-text">
        Delete
      </Menu.Item>
    </Menu>
  );

  const shareMenu = (
    <Menu onClick={handleAdShare}>
      <Menu.Item key="facebook"></Menu.Item>
      <Menu.Item key="twitter"></Menu.Item>
    </Menu>
  );

  const isToday = (someDate) => {
    const today = new Date();
    const someDateArray = someDate.split(" ")[0].split("-");
    return (
      someDateArray[2] === today.getDate() &&
      someDateArray[1] === today.getMonth() + 1 &&
      someDateArray[0] === today.getFullYear()
    );
  };

  return (
    <div className="single-post-container" id={advertisement.id} tabindex="-1">
      <div className="post-header">
        <div>
          <Avatar size={35}>{`${group.name
            .substring(0, 1)
            .toUpperCase()}`}</Avatar>
        </div>
        <div className="post-header-name">
          <div className="post-owner-name">
            {`${group.name.substring(0, 1).toUpperCase()}${group.name.substring(
              1,
              group.name.length
            )}`}
          </div>
          <div className="post-time">
            {isToday(advertisement.created)
              ? moment.utc(advertisement.created).local().format("LT")
              : moment.utc(advertisement.created).local().format("L")}
            {advertisement.modified !== advertisement.created && (
              <div className="post-edited">Edited</div>
            )}
          </div>
        </div>
        {(group.owner_id === user.id || advertisement.user_id === user.id) &&
          !userBlocked && (
            <div className="post-action">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottomLeft"
                arrow
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown>
            </div>
          )}
      </div>
      <div className="post-content">
        <div className="wall-text">{advertisement.text}</div>
        <div className="post-media">
          {advertisement.attachments && (
            <>
              {advertisement.attachments.substring(
                advertisement.attachments.length - 3,
                advertisement.attachments.length
              ) == "mp4" ? (
                <video controls className="post-attachments">
                  <source src={advertisement.attachments} type="video/mp4" />
                </video>
              ) : (
                <img
                  src={advertisement.attachments}
                  className="post-attachments"
                />
              )}
            </>
          )}
        </div>
        <hr />
        <div className="post-like-comment">
          <div
            className={liked ? "post-like-active" : "post-like"}
            style={{ pointerEvents: userBlocked ? "none" : "all" }}
          >
            <FontAwesomeIcon
              icon="thumbs-up"
              onClick={async () => {
                if (liked) {
                  await unlikeAdvertisement(advertisement.id);
                  return;
                }
                await likeAdvertisement(advertisement.id);
              }}
            />
            <h3
              onClick={() => setShowLikeModal(true)}
            >{`${advertisement.likeData.length} likes`}</h3>
          </div>
          <div className="post-comment">
            <h3
              onClick={() => setShowCommentModal(true)}
            >{`${commentData.length} comments`}</h3>
            <FontAwesomeIcon
              icon="comments"
              onClick={() => document.getElementById("comment-input").focus()}
            />
          </div>
          <div className="post-comment">
            <h3
              onClick={() => setShowShareModal(true)}
            >{`${advertisement.shareData.length} shares`}</h3>

            <Dropdown
              overlay={shareMenu}
              trigger={["click"]}
              placement="bottomLeft"
              arrow
            >
              <FontAwesomeIcon
                icon="share"
                // onClick={() => document.getElementById("comment-input").focus()}
              />
            </Dropdown>
          </div>
        </div>
      </div>
      <CommentAdForm
        commentAdvertisement={(data) =>
          commentAdvertisement(advertisement.id, data)
        }
      />
      <div className="recent-comments">
        <hr />
        {commentData.length && commentData.length > 2
          ? commentData
              .filter((elem, index) => index < 2)
              .map((comment) => (
                <Comment
                  comment={comment}
                  user={user}
                  isToday={isToday}
                  deleteComment={() =>
                    deleteComment(
                      comment.id,
                      comment.activity_wall_id,
                      comment.sender_id
                    )
                  }
                  group={group}
                  entity={entity}
                  advertisement={advertisement}
                  userBlocked={userBlocked}
                />
              ))
          : commentData.length
          ? commentData.map((comment) => (
              <Comment
                comment={comment}
                user={user}
                isToday={isToday}
                deleteComment={() =>
                  deleteComment(
                    comment.id,
                    comment.activity_wall_id,
                    comment.sender_id
                  )
                }
                group={group}
                entity={entity}
                advertisement={advertisement}
                userBlocked={userBlocked}
              />
            ))
          : "There is no comment on this Post"}
      </div>
      <LikeModal
        showModal={showLikeModal}
        setShowModal={setShowLikeModal}
        likeData={advertisement.likeData}
      />
      <CommentModal
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
        commentData={commentData}
        isToday={isToday}
        user={user}
        advertisement={advertisement}
        deleteComment={deleteComment}
        userBlocked={userBlocked}
        group={group}
      />
      <ShareModal
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        shareData={advertisement.shareData}
      />
      <CreateAdModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        handleSubmit={(values) => {
          updateAdvertisement(values);
          setShowUpdateModal(false);
        }}
        isSubmitting={isSubmitting}
        advertisement={advertisement}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        deleteAdvertisement={() => deleteAdvertisement(advertisement.id)}
      />
    </div>
  );
};

export default SingleAdvertisement;
