//** Import Modules */
import {
  faEdit,
  faGlobe,
  faMapMarkedAlt,
  faPhone,
  faStore,
  faUtensils,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axiosGeneral from "../../utils/axiosGeneral";
import { Button, Modal, Alert } from "antd";
import { useSelector } from "react-redux";
import React, { useState } from "react";

//** Build Each Widget Component */
// Channel Widget
const ChannelWidget = (props) => {
  const { business } = props;
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);

  const [officeModal, setOfficeModal] = useState(false);

  const closeOfficeModal = () => {
    setOfficeModal(false);
  };

  const [menuModal, setMenuModal] = useState(false);

  const closeMenuModal = () => {
    setMenuModal(false);
  };

  return (
    <div
      className="profile-widget"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {user.id !== undefined &&
        user.id !== business.owner_id &&
        business.meta_data.business_category === "Office" && (
          <Button
            type="primary"
            style={{ maxWidth: "350px" }}
            onClick={() => {
              navigate(`services`);
            }}
          >
            <FontAwesomeIcon icon={faCalendarCheck} />
            Make an appointment
          </Button>
        )}
      {user.id === undefined &&
        business.meta_data.business_category === "Office" && (
          <>
            <Button
              onClick={() => setOfficeModal(true)}
              type="primary"
              style={{ maxWidth: "350px" }}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />
              Make an appointment
            </Button>
            <Modal
              title="Make an appointment"
              visible={officeModal}
              onCancel={closeOfficeModal}
              footer={null}
            >
              <div className="warning-boxes">
                <Alert
                  message="You need to be logged in to make an appointment"
                  description={
                    <>
                      <h4>
                        Please login/register{" "}
                        <a
                          href={
                            "http://localhost:3005/?redirect=http://localhost:3000/business/" +
                            business.meta_data.business_handle
                          }
                        >
                          HERE
                        </a>
                      </h4>
                      <h4>Thank you!</h4>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </Modal>
          </>
        )}

      {/* {user.id !== undefined &&
        business.meta_data.business_category === "Restaurant" && (
          <>
            <Button
              type="primary"
              style={{ maxWidth: "350px" }}
              onClick={() => {
                navigate(`menu`);
              }}
            >
              <FontAwesomeIcon icon={faUtensils} />
              Begin Ordering Food
            </Button>
          </>
        )}

      {user.id === undefined &&
        business.meta_data.business_category === "Restaurant" && (
          <>
            <Button
              onClick={() => setMenuModal(true)}
              type="primary"
              style={{ maxWidth: "350px" }}
            >
              <div className="title-icon">
                <FontAwesomeIcon icon={faUtensils} />
              </div>
              Begin Ordering Food
            </Button>
            <Modal
              title="Restaurant"
              visible={menuModal}
              onCancel={closeMenuModal}
              footer={null}
            >
              <div className="warning-boxes">
                <Alert
                  message="You need to be logged in to make an order"
                  description={
                    <>
                      <h4>
                        Please login/register{" "}
                        <a
                          href={
                            "http://localhost:3005/?redirect=http://localhost:3000/business/" +
                            business.meta_data.business_handle
                          }
                        >
                          HERE
                        </a>
                      </h4>
                      <h4>Thank you!</h4>
                    </>
                  }
                  type="warning"
                  showIcon
                />
              </div>
            </Modal>
          </>
        )} */}

      {business.type === "store" && (
        <>
          <div className="title-txt">Visit Store</div>
          <div className="title-icon">
            <FontAwesomeIcon icon={faStore} />
          </div>
        </>
      )}
    </div>
  );
};

// Website Widget
const WebsiteWidget = (props) => {
  const { website } = props;

  return (
    <div className="profile-widget">
      <div className="widget-info-box website">
        <div className="button" style={{ cursor: "default" }}>
          <div className="profile-widget-title" style={{ cursor: "default" }}>
            <div className="title-txt" style={{ cursor: "default" }}>
              Website
            </div>
            <div className="title-icon" style={{ cursor: "default" }}>
              <FontAwesomeIcon icon={faGlobe} />
            </div>
          </div>

          <div className="profile-widget-info" style={{ cursor: "default" }}>
            {website}
          </div>
        </div>
      </div>
    </div>
  );
};

// Phone Widget
const PhoneWidget = (props) => {
  const { phone, business, userId } = props;

  //* Call phone number when clicked
  const callBusiness = async () => {
    if (phone) {
      const response = await axiosGeneral().post(
        `${process.env.REACT_APP_BE_API_URL}/api/business/visitor`,
        {
          typeId: business.id,
          component: "phone_click",
          userId: userId,
          type: "business",
        }
      );
      window.open(`tel:${phone}`, "_self");
    }
  };

  //* Function to format phone number
  const formatPhone = (phone) => {
    if (phone) {
      //normalize string and remove all unnecessary characters
      const newPhone = phone.replace(/[^\d]/g, "");

      if (newPhone.length === 10) {
        return newPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (newPhone.length === 11) {
        return newPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
      }
    }

    return phone;
  };

  return (
    <div className="profile-widget">
      <div className="widget-info-box phone">
        <button className="button" onClick={callBusiness}>
          <div className="profile-widget-title">
            <div className="title-txt">Phone Number</div>
            <div className="title-icon">
              <FontAwesomeIcon icon={faPhone} />
            </div>
          </div>

          <div className="profile-widget-info">Call {formatPhone(phone)}</div>
        </button>
      </div>
    </div>
  );
};

// Address & Map widget
const AddressWidget = (props) => {
  const { business, userId } = props;

  //* Prepare the prominent variables
  const address = business.meta_data.business_address;
  const address2 = business.meta_data.business_address_2;
  const city = business.meta_data.business_city;
  const state = business.meta_data.business_state;
  const zip = business.meta_data.business_zip;
  //const country = business.meta_data.business_country;

  //* Format address for Google Map
  const addressFormat = `${address}, ${city} ${state} ${zip}`;

  //* Open Google Map Directions when clicked
  const openDirections = async () => {
    const response = await axiosGeneral().post(
      `${process.env.REACT_APP_BE_API_URL}/api/business/visitor`,
      {
        typeId: business.id,
        component: "address_click",
        userId: userId,
        type: "business",
      }
    );
    window.open(`https://www.google.com/maps/dir//${addressFormat}`);
  };

  return (
    <div className="profile-widget">
      <div className="business-address-widget">
        <div className="widget-info-box address">
          <button className="button" onClick={openDirections}>
            <div className="profile-widget-title">
              <div className="title-txt">Address</div>
              <div className="title-icon">
                <FontAwesomeIcon icon={faMapMarkedAlt} />
              </div>
            </div>

            <div className="profile-widget-info">
              {address} {address2}
              <br />
              {city}, {state} {zip}
            </div>
          </button>
        </div>

        <div className="map-box">
          <iframe
            title="map"
            width="100%"
            height="350"
            frameBorder="0"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC0J36wM52Kf5lP1YHc0N2ME4mhmuzoub8&q=${addressFormat}`}
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default function ProfileWidgets(props) {
  const { business, userId } = props;

  return (
    <div>
      <ChannelWidget business={business} />

      <WebsiteWidget
        website={business.meta_data.business_website}
        business={business}
        userId={userId}
      />
      <PhoneWidget
        phone={business.meta_data.business_phone}
        business={business}
        userId={userId}
      />
      <AddressWidget business={business} userId={userId} />
    </div>
  );
}
