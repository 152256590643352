//** Import Components */
import {
  faArrowAltCircleDown,
  faPlayCircle,
} from "@fortawesome/free-regular-svg-icons";
import { faHandHolding, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useEffect, useState } from "react";
import axiosGeneral from "../../utils/axiosGeneral";
import HomepageHeroSearch from "./HomepageHeroSearch";
import BizzllVideoPlayerModal from "../../components/common/BizzllVideoPlayerModal";

//** Import Assets */
import logo from "../../assets/images/bizzll-logo.png";
import heroBG from "../../assets/images/home-slide.jpg";
import joinMovement from "../../assets/images/join-movement.png";

export default function HomepageHero(props) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  useEffect(() => {
    getAllCities();
  }, []);

  const getAllCities = async () => {
    const response = await axiosGeneral().get(
      `${process.env.REACT_APP_BE_API_URL}/api/city/getAllCities`
    );
    if (response && response.status == 200) {
      if (response.data && response.data.length) {
        setCities(
          response.data.map((city) => {
            return {
              ...city,
              value: city.name,
            };
          })
        );
      }
    }
  };

  const scrollToContent = (elm) => {
    gsap.to(window, {
      duration: 2,
      scrollTo: { y: elm, offsetY: 130 },
    });
  };

  const btnTxt = (
    <>
      <FontAwesomeIcon icon={faPlayCircle} /> What is <img src={logo} alt="" />
    </>
  );

  return (
    <div id="home-hero">
      <div
        className="hero-container"
        style={{ backgroundImage: `url(${heroBG})` }}
      >
        <div className="hero-inner">
          <div className="container">
            <div className="intro-text">
              <h2>Buy Local And Support Your City</h2>

              <h3>Grow Your Network, Grow Your Business</h3>

              <p>
                Search for businesses, build relationships, evolve your business
                to digital, boost our local economic opportunity, and thrive
                with the <img src={logo} alt="" /> marketing power
              </p>
            </div>

            <div className="hero-cta">
              <div className="hero-btns">
                <img
                  src={joinMovement}
                  alt="Join The Movement"
                  className="join-movement"
                />

                <span>
                  <button onClick={() => scrollToContent("#why-bizzll")}>
                    <FontAwesomeIcon icon={faArrowAltCircleDown} /> Learn More
                  </button>
                </span>
                <span>
                  <button onClick={() => scrollToContent("#live-tv")}>
                    <FontAwesomeIcon icon={faTv} /> Bizzll TV
                  </button>
                </span>
                <span>
                  <BizzllVideoPlayerModal
                    source="https://richmadedesign.com/bizzll-home-video.mp4"
                    buttonTxt={btnTxt}
                  />
                </span>
                <span>
                  <button onClick={() => scrollToContent("#packages")}>
                    <FontAwesomeIcon icon={faHandHolding} /> Get Started
                  </button>
                </span>
              </div>

              <div className="hero-sidebar">
                <HomepageHeroSearch cities={cities} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
