// MessageItem.js
import React from "react";
import { Badge } from "antd";
import { DASHBOARD_URL } from "../../common/constants";

export default function MessageItem({
  type,
  name,
  unseenMessages,
  businessId,
}) {
  const manageBusiness = () => {
    localStorage.setItem("editBusiness", businessId);

    window.location.replace(`${DASHBOARD_URL}/business`);
  };

  return (
    <>
      {type === "business" ? (
        <div
          className="message-notification-user-bar business-notification"
          onClick={manageBusiness}
        >
          <span>
            <b>{name}:</b>{" "}
            <Badge
              count={unseenMessages}
              overflowCount={10}
              size="default"
            ></Badge>
          </span>
        </div>
      ) : (
        <div className="message-notification-user-bar">
          <span>
            <b>{name}:</b>{" "}
            <Badge
              count={unseenMessages}
              overflowCount={10}
              size="default"
            ></Badge>
          </span>
        </div>
      )}
    </>
  );
}
